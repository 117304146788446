import React from "react"

import Background from "../components/background"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Container from "../components/bootstrap/container"
import PageTitle from "../components/page-title"
import Row from "../components/bootstrap/row"
import ImgDBrankinProfile from "../components/img-dbrankin-profile"
import ImgSTseProfile from "../components/img-stse-profile"
import ImgSBoydProfile from "../components/img-sboyd-profile"
import ImgRMcneilProfile from "../components/img-rmcneil-profile"
import { FaPlus, FaUser } from "react-icons/fa"
import AgendaDay from "../components/agenda-day"
import AgendaItem from "../components/agenda-item"
import AgendaTime from "../components/agenda-time"
import AgendaTitle from "../components/agenda-title"
import LakesideSoftwareLogoImage from "../components/lakeside-software-logo-image"
// import Timeline from "../components/timeline"
// import { Link } from "gatsby"
import GridLines from "../components/gridlines"
// import FormCheck from "../components/form-check"
// import Row from "../components/bootstrap/row"
import PartnerCard from "../components/partner-card"
import { OutboundLink } from "gatsby-plugin-google-analytics"
import PrimaryButton from "../components/primary-button"

// Public Sector
import JaneHamiltonImg from "../images/speaker-profiles/jhamilton.jpg"
import DanUnsworthImg from "../images/speaker-profiles/dunsworth.jpg"
import KateGuthrieImg from "../images/speaker-profiles/kguthrie.jpg"
import DanielInghamImg from "../images/speaker-profiles/dingham.jpg"
import JoCausonImg from "../images/speaker-profiles/jcauson.jpg"
import ShonaAdamsImg from "../images/speaker-profiles/sadams.jpg"
import PatRitchieImg from "../images/speaker-profiles/pritchie.png"
import JoannaDavinsonImg from "../images/speaker-profiles/jdavinson.jpg"
import DavidTaylorImg from "../images/speaker-profiles/dtaylor.jpg"
import HelenLedererImg from "../images/speaker-profiles/hlederer.jpg"
import AndyLakeImg from "../images/speaker-profiles/alake.jpg"
import PeterCheeseImg from "../images/speaker-profiles/pcheese.jpg"
import MarkBrunetteImg from "../images/speaker-profiles/mbrunette.jpg"
import KevinRowanImg from "../images/speaker-profiles/krowan.jpg"

import BethFarminerImg from "../images/speaker-profiles/bfarminer.jpg"
import ClareFofanaImg from "../images/speaker-profiles/cfofana.jpg"
import StuartWattsImg from "../images/speaker-profiles/swatts.jpg"
import RuthSmethurstImg from "../images/speaker-profiles/rsmethurst.png"
import LauraChapmanImg from "../images/speaker-profiles/lchapman.png"
import MichelleOwensImg from "../images/speaker-profiles/mowens.jpg"
import LeahJonesImg from "../images/speaker-profiles/ljones.jpg"
import DebbieAlderImg from "../images/speaker-profiles/dalder.jpg"
import LauraFaulknerImg from "../images/speaker-profiles/lfaulkner.jpg"
import JennyBaynesImg from "../images/speaker-profiles/jbaynes.jpg"
import SaraEdwardsImg from "../images/speaker-profiles/sedwards.jpg"
import AndrewNelsonImg from "../images/speaker-profiles/anelson.jpg"
import KateJacksonImg from "../images/speaker-profiles/kjackson.jpg"
import SteveDowneyImg from "../images/speaker-profiles/sdowney.jpg"
import RichardPriceImg from "../images/speaker-profiles/rprice.jpg"
import CliveAndersonImg from "../images/speaker-profiles/canderson.png"
import MiguelGodfreyImg from "../images/speaker-profiles/mgodfrey.png"
import KatieJacobsImg from "../images/speaker-profiles/kjacobs.png"
import AidenKearneyImg from "../images/speaker-profiles/akearney.png"
import CharlotteDuncanImg from "../images/speaker-profiles/cduncan.png"
import PamRobertsImg from "../images/speaker-profiles/proberts.png"
import DWImg from "../images/speaker-profiles/dw.png"
import JasBilenImg from "../images/speaker-profiles/jbilen.png"
import StevenBoydImg from "../images/speaker-profiles/sboyd.png"
import AnneBurtImg from "../images/speaker-profiles/aburt.png"
import IanEllisonImg from "../images/speaker-profiles/iellison.png"
import PerryTimmsImg from "../images/speaker-profiles/ptimms.png"
import RaviChandImg from "../images/speaker-profiles/rchand.png"
import NicolaMillardImg from "../images/speaker-profiles/nmillard.png"

import AlGardnerImg from "../images/speaker-profiles/agardner.png"
import NeilWilliamsImg from "../images/speaker-profiles/nwilliams.png"
import LouisaLeRouxImg from "../images/speaker-profiles/llroux.jpeg"
import DominicBrankinImg from "../images/speaker-profiles/dbrankin.jpg"
import GrantSmithImg from "../images/speaker-profiles/gsmith.jpeg"
import KetanPatelImg from "../images/speaker-profiles/kpatel.jpeg"
import ClaireStoreyImg from "../images/speaker-profiles/cstorey.png"
import JudithAverisImg from "../images/speaker-profiles/javeris.png"
import AndrewGoodmanImg from "../images/speaker-profiles/agoodman.jpeg"
import NicolaYoungImg from "../images/speaker-profiles/nyoung.jpeg"

// Silhouettes
import FemaleSilhouetteImg from "../images/speaker-profiles/female.png"
import MaleSilhouetteImg from "../images/speaker-profiles/male.png"

// Private Sector
import NeilCreminImg from "../images/speaker-profiles/ncremin.jpg"
import SimonSallowayImg from "../images/speaker-profiles/ssalloway.jpg"
import ZoeMascarenhasImg from "../images/speaker-profiles/zmascarenhas.jpg"
import NickMcIntoshImg from "../images/speaker-profiles/nmcintosh.jpg"
import ScottSomanthalImg from "../images/speaker-profiles/ssomenthal.jpg"
import RobinSouthallImg from "../images/speaker-profiles/rsouthall.jpg"
import VijayMagonImg from "../images/speaker-profiles/vmagon.jpg"
import JamesSarsonImg from "../images/speaker-profiles/jsarson.jpg"
import MicaelaMaieruImg from "../images/speaker-profiles/mmaieru.jpg"
import SimonShepherdImg from "../images/speaker-profiles/sshepherd.jpg"
import TobyHaylesImg from "../images/speaker-profiles/thayles.png"
import CosimaVonkriesImg from "../images/speaker-profiles/cvonkries.jpg"
import KieranCoughlanImg from "../images/speaker-profiles/kcoughlan.png"
import JamesWatkinsImg from "../images/speaker-profiles/jwatkins.jpg"
import PaulTurnerImg from "../images/speaker-profiles/pturner.jpg"
import TitaAtangImg from "../images/speaker-profiles/tatang.jpeg"
import JeremyEdwardsImg from "../images/speaker-profiles/jedwards.png"

import SectionTitle from "../components/section-title"
import MyAgenda from "../components/my-agenda"

// Logo
import BytesLogo from "../images/logos/bytes.png"
import MicrosoftLogo from "../images/logos/microsoft.png"
import RingCentralLogo from "../images/logos/ringcentral.png"
import ZoomLogo from "../images/logos/zoom.png"
import AdobeLogo from "../images/logos/adobe.png"
import PolyLogo from "../images/logos/poly.png"
import LakesideSoftwareImg from "../images/speaker-profiles/lakeside-software.png"
import DWPDigitalLogo from "../images/speaker-profiles/dwp-digital.png"

import { MyAgendaProvider } from "../providers/my-agenda"
import MyAgendaBtn from "../components/my-agenda-btn"
import AgendaDescription from "../components/agenda-description"

const ConferencePage = () => (
  <Background>
    <MyAgendaProvider>
    <Layout>
      <Seo title="Virtual Conference &amp; Exhibition" />
      {/* <Container>
        <PageTitle text="Virtual Conference &amp; Exhibition" />
        <h2 style={{
          color: `#fff`,
          fontFamily: `Avenir`,
          fontWeight: `900`,
          fontSize: `2.5rem`,
          textAlign: `center`,
          margin: `3rem 0`
        }}>The Event<br /><span style={{
          fontSize: `1rem`
        }}>In Numbers</span></h2>

        <Row>
          <div className="col text-center">1200+<br />Attendees</div>
          <div className="col text-center">40+<br />Speakers</div>
          <div className="col text-center">24<br />Workshops</div>
          <div className="col text-center">45+<br />Partners</div>
        </Row>

        <h2 style={{
          color: `#fff`,
          fontFamily: `Avenir`,
          fontWeight: `900`,
          fontSize: `2.5rem`,
          textAlign: `center`,
          margin: `3rem 0`
        }}>Keynotes<br /><span style={{
          fontSize: `1rem`
        }}>Have included</span></h2>

        <Row>
          <div className="col text-center">Alex Chisholm<br />Chief Operating Officer for the Civil Service and Permanent Secretary</div>
          <div className="col text-center">Debbie Alder<br />Director General, People, Capability &amp; Place<br />Department for Work and Pensions</div>
          <div className="col text-center">Rupert McNeil<br />Former Chief People Officer<br />Cabinet Office</div>
          <div className="col text-center">Stephen Boyd<br />Chief Executive<br />Government Property Agency</div>
        </Row>
        <br /><br />
        <Row>
          <div className="col text-center">Peter Cheese<br />Chief Executive<br />CIPD</div>
          <div className="col text-center">Jo Causon<br />Chief Executive<br />Institute of Customer Service</div>
          <div className="col text-center">Simon Tse<br />Chief Executive<br />Crown Commercial Service</div>
          <div className="col text-center">Joanna Davinson<br />Executive Director, Central Digital and Data Office<br />Cabinet Office</div>
        </Row>

        <h2 style={{
          color: `#fff`,
          fontFamily: `Avenir`,
          fontWeight: `900`,
          fontSize: `2.5rem`,
          textAlign: `center`,
          margin: `3rem 0`
        }}>Supported By</h2>

        <Row>
          <div className="col text-center">Adobe</div>
          <div className="col text-center">Lakeside Software</div>
          <div className="col text-center">Iron Mountain</div>
          <div className="col text-center">NFON</div>
          <div className="col text-center">NHS Business Services Authority</div>
          <div className="col text-center">Wazoku</div>
          <div className="col text-center">Zivver</div>
          <div className="col text-center">CCube Solutions</div>
          <div className="col text-center">Quadient</div>
          <div className="col text-center">Nintex</div>
        </Row>

        <h2 style={{
          color: `#fff`,
          fontFamily: `Avenir`,
          fontWeight: `900`,
          fontSize: `2.5rem`,
          textAlign: `center`,
          margin: `3rem 0`
        }}>Register for 2023</h2>

        <p className="text-center text-white">Register your interest for the 2023 event and we will keep you up-to-date on its progress and how you can be involved.</p>
        <p className="text-center text-white">Form to complete</p>
      </Container> */}
      <div>
        <Container>
          <PageTitle text="Virtual Conference &amp; Exhibition" />

          <div className="text-center mb-5 pb-3" hidden>
            <a href="#registration" style={{
              backgroundColor: `#fff`,
              borderColor: `#fff`,
              color: `#d23464`,
              textTransform: `uppercase`,
              borderRadius: `0`,
              padding: `.6rem 1rem`,
              fontWeight: `600`
            }}>Register to Attend</a>
          </div>

          {/* <SectionTitle text="Week 1 Workshops" /> */}
          <div>
          <Row>
            <div className="col-lg p-2" style={{
              fontFamily: `Din Pro`
            }}>
              <AgendaDay title="Wednesday" day="1" subtitle="February 2023" description="Future Focused and Sustainable Property" date="1st February 2023" color="#ff8c27" sessionId="2023-02-01">
                
                <AgendaItem>
                  <AgendaTitle title="Future Focused and Sustainable Property" />
                </AgendaItem>

                <div className="shadow-sm p-3 mb-3 rounded text-white" style={{ backgroundColor: `#ff8c27` }}>
                  <div>10:00</div>
                  <div className="font-weight-bold">Sustainability in the workplace</div>
                  <div className="row my-3">
                    <div className="col">
                      <div className="rounded-circle m-auto overflow-hidden border" style={{ width: `60px`, height: `60px`, backgroundColor: `rgb(255,255,255,0.6)`}}>
                        <img src={CliveAndersonImg} className="img-fluid" />
                      </div>
                      <div className="mt-3">Clive Anderson</div>
                      <div className="font-weight-lighter"><small>Director of Capital Projects, GPA</small></div>
                    </div>
                    <div className="col">
                      <div className="rounded-circle m-auto overflow-hidden border" style={{ width: `60px`, height: `60px`, backgroundColor: `rgb(255,255,255,0.6)`}}>
                        <img src={MiguelGodfreyImg} className="img-fluid" />
                      </div>
                      <div className="mt-3">Dr Miguel Godfrey</div>
                      <div className="font-weight-lighter"><small>Head of Sustainability, GPA</small></div>
                    </div>
                  </div>
                  <div className="row my-3">
                    <div className="col">
                      <div className="rounded-circle m-auto overflow-hidden border" style={{ width: `60px`, height: `60px`, backgroundColor: `rgb(255,255,255,0.6)`}}>
                        <img src={FemaleSilhouetteImg} className="img-fluid" />
                      </div>
                      <div className="mt-3">Melanie Weaver</div>
                      <div className="font-weight-lighter"><small>GPA</small></div>
                    </div>
                    <div className="col">
                      <div className="rounded-circle m-auto overflow-hidden border" style={{ width: `60px`, height: `60px`, backgroundColor: `rgb(255,255,255,0.6)`}}>
                        <img src={LouisaLeRouxImg} className="img-fluid" />
                      </div>
                      <div className="mt-3">Louisa Le Roux</div>
                      <div className="font-weight-lighter"><small>Net Zero Programme Manager, GPA</small></div>
                    </div>
                  </div>
                  {/* <div className="text-center"><small>Government Property Agency</small></div> */}
                  <hr />
                  <div>
                    <small>
                    <p className="my-1 font-weight-bold">Topics to be covered:</p>
                    <p className="my-1">1. How do you innovate while also being sustainable</p>
                    <p className="my-1">2. Challenges and opportunities of net zero</p>
                    </small>
                  </div>
                </div>
              </AgendaDay>
            </div>
            <div className="col-lg p-2" style={{
              fontFamily: `Din Pro`
            }}>
              <AgendaDay title="Thursday" day="2" subtitle="February 2023" description="Putting People First" date="2nd February 2023" color="#d22c5b" sessionId="2023-02-02">
                <AgendaItem>
                  <AgendaTitle title="Putting People First" />
                </AgendaItem>

                <div className="shadow-sm p-3 mb-3 rounded text-white" style={{ backgroundColor: `#d22c5b` }}>
                  <div>10:00</div>
                  <div className="font-weight-bold">Smarter working tools to build inclusion and employee voice</div>
                  <div className="row my-3">
                    <div className="col">
                      <div className="rounded-circle m-auto overflow-hidden border" style={{ width: `60px`, height: `60px`, backgroundColor: `rgb(255,255,255,0.6)`}}>
                        <img src={KatieJacobsImg} className="img-fluid" />
                      </div>
                      <div className="mt-3">Katie Jacobs</div>
                      <div className="font-weight-lighter"><small>Senior Stakeholder Lead, CIPD</small></div>
                    </div>
                    <div className="col">
                      <div className="rounded-circle m-auto overflow-hidden border" style={{ width: `60px`, height: `60px`, backgroundColor: `rgb(255,255,255,0.6)`}}>
                        <img src={FemaleSilhouetteImg} className="img-fluid" />
                      </div>
                      <div className="mt-3">Debra Biggs</div>
                      <div className="font-weight-lighter"><small>Cabinet Office</small></div>
                    </div>
                    <div className="col">
                      <div className="rounded-circle m-auto overflow-hidden border" style={{ width: `60px`, height: `60px`, backgroundColor: `rgb(255,255,255,0.6)`}}>
                        <img src={FemaleSilhouetteImg} className="img-fluid" />
                      </div>
                      <div className="mt-3">Dawn Lennie</div>
                      <div className="font-weight-lighter"><small>Civil Service HR, Cabinet Office</small></div>
                    </div>
                  </div>
                  <hr />
                  <div>
                    <small>
                    <p className="my-1 font-weight-bold">Topics to be covered:</p>
                    <p className="my-1">1. Inclusion and wellbeing - creating the right behaviours, inclusive design</p>
                    <p className="my-1">2. People-focused change - playbooks in use to support individuals and teams</p>
                    <p className="my-1">3 .How asks for help from staff have changed  - listening and adapting your offer</p>
                    </small>
                  </div>
                </div>
              </AgendaDay>
            </div>
          </Row>
          </div>

          {/* <SectionTitle text="Week 2 Workshops" /> */}
          <div>
          <Row>
            <div className="col-lg p-2" style={{
              fontFamily: `Din Pro`
            }}>
              <AgendaDay title="Tuesday" day="7" subtitle="February 2023" description="Outstanding Smarter Working Leadership" date="7th February 2023" color="#ff8c27" sessionId="2023-02-07">
                <AgendaItem>
                  <AgendaTitle title="Outstanding Smarter Working Leadership" />
                </AgendaItem>

                <div className="shadow-sm p-3 mb-3 rounded text-white" style={{ backgroundColor: `#ff8c27` }}>
                  <div>10:00</div>
                  <div className="font-weight-bold">Leading (and being led through) a transformational culture change</div>
                  <div className="row my-3">
                    <div className="col">
                      <div className="rounded-circle m-auto overflow-hidden border" style={{ width: `60px`, height: `60px`, backgroundColor: `rgb(255,255,255,0.6)`}}>
                        <img src={NicolaMillardImg} className="img-fluid" />
                      </div>
                      <div className="mt-3">Nicola Millard</div>
                      <div className="font-weight-lighter"><small>Presenter, Writer & Researcher on Futures, Psychology and Innovation</small></div>
                    </div>
                    <div className="col">
                      <div className="rounded-circle m-auto overflow-hidden border" style={{ width: `60px`, height: `60px`, backgroundColor: `rgb(255,255,255,0.6)`}}>
                        <img src={RaviChandImg} className="img-fluid" />
                      </div>
                      <div className="mt-3">Ravi Chand</div>
                      <div className="font-weight-lighter"><small>Director Places for Growth / Beyond Whitehall, The Office for Government Property</small></div>
                    </div>
                  </div>
                  <hr />
                  <div>
                    <small>
                    <p className="my-1 font-weight-bold">Topics to be covered:</p>
                    <p className="my-1">1. Being a role model - the challenges</p>
                    <p className="my-1">2. Trusting your people through outcomes based leadership - how to influence leaders</p>
                    <p className="my-1">3. New leadership skills in a smarter working world - the impact of good leadership</p>
                    <p className="my-1">4. Helping your leader to be a better leader - smarter working tools</p>
                    </small>
                  </div>
                </div>
              </AgendaDay>
            </div>
            <div className="col-lg p-2" style={{
              fontFamily: `Din Pro`
            }}>
              <AgendaDay title="Wednesday" day="8" subtitle="February 2023" description="Small Scale, Big Impact" date="8th February 2023" color="#d22c5b" sessionId="2023-02-08">
                <AgendaItem>
                  <AgendaTitle title="Small Scale, Big Impact" />
                </AgendaItem>

                <div className="shadow-sm p-3 mb-3 rounded text-white" style={{ backgroundColor: `#d22c5b` }}>
                  <div>10:00</div>
                  <div className="font-weight-bold">Creating and sustaining an active network of smarter working practitioners</div>
                  <div className="row my-3">
                    <div className="col">
                      <div className="rounded-circle m-auto overflow-hidden border" style={{ width: `60px`, height: `60px`, backgroundColor: `rgb(255,255,255,0.6)`}}>
                        <img src={FemaleSilhouetteImg} className="img-fluid" />
                      </div>
                      <div className="mt-3">Hannah Hiles</div>
                      <div className="font-weight-lighter"><small>Smarter Working Lead, Department for International Trade</small></div>
                    </div>
                    <div className="col">
                      <div className="rounded-circle m-auto overflow-hidden border" style={{ width: `60px`, height: `60px`, backgroundColor: `rgb(255,255,255,0.6)`}}>
                        <img src={BethFarminerImg} className="img-fluid" />
                      </div>
                      <div className="mt-3">Beth Farminer</div>
                      <div className="font-weight-lighter"><small>Continuous Improvement Lead - Business Transformation, Ofwat</small></div>
                    </div>
                  </div>
                  <hr />
                  <div>
                    <small>
                    <p className="my-1 font-weight-bold">Topics to be covered:</p>
                    <p className="my-1">1. Building and sustaining a vibrant smarter working community</p>
                    <p className="my-1">2. Cross-government change managers and CI network</p>
                    </small>
                  </div>
                </div>
              </AgendaDay>
            </div>
            <div className="col-lg p-2" style={{
              fontFamily: `Din Pro`
            }}>
              <AgendaDay title="Thursday" day="9" subtitle="February 2023" description="Championing Continuous Improvement" date="9th February 2023" color="#b000a9" sessionId="2023-02-09">
                <AgendaItem>
                  <AgendaTitle title="Championing Continuous Improvement" />
                </AgendaItem>

                <div className="shadow-sm p-3 mb-3 rounded text-white" style={{ backgroundColor: `#b000a9` }}>
                  <div>10:00</div>
                  <div className="font-weight-bold">What next for smarter working standards?</div>
                  <div className="row my-3">
                    <div className="col">
                      <div className="rounded-circle m-auto overflow-hidden border" style={{ width: `60px`, height: `60px`, backgroundColor: `rgb(255,255,255,0.6)`}}>
                        <img src={StuartWattsImg} className="img-fluid" />
                      </div>
                      <div className="mt-3">Stuart Watts</div>
                      <div className="font-weight-lighter"><small>Smarter Working Programme Lead, GPA</small></div>
                    </div>
                    <div className="col">
                      <div className="rounded-circle m-auto overflow-hidden border" style={{ width: `60px`, height: `60px`, backgroundColor: `rgb(255,255,255,0.6)`}}>
                        <img src={AndyLakeImg} className="img-fluid" />
                      </div>
                      <div className="mt-3">Andy Lake</div>
                      <div className="font-weight-lighter"><small>Author of "Smart Flexibility" &amp; Co-Founder of the European Smart Work Network</small></div>
                    </div>
                  </div>
                  <hr />
                  <div>
                    <small>
                    <p className="my-1 font-weight-bold">Topics to be covered:</p>
                    <p className="my-1">1. Involving people in the change process - designing together and keeping momentum</p>
                    <p className="my-1">2. Sustaining smarter working for the long term - beyond PAS3000</p>
                    </small>
                  </div>
                </div>
              </AgendaDay>
            </div>
          </Row>
          </div>

          {/* <SectionTitle text="Week 3 Workshops" /> */}
          <Row>
            <div className="col-lg p-2" style={{
              fontFamily: `Din Pro`
            }} hidden>
              
              <AgendaDay title="Tuesday" day="14" subtitle="February 2023" description="Automation, Artificial Intelligence and Machine Learning" date="14th February 2023" color="#ff8c27" sessionId="2023-02-14">

                <AgendaItem>
                  <AgendaTitle title="Automation, Artificial Intelligence and Machine Learning" />
                </AgendaItem>

                <div className="shadow-sm p-3 mb-3 rounded text-white" style={{ backgroundColor: `#ff8c27` }}>
                  <div>10:00</div>
                  <div className="font-weight-bold">The Principal Tool for Data Evaluation at DWP</div>
                  <hr />
                  <div>
                    <small>
                    <p className="my-1 font-weight-bold">Topics to be covered:</p>
                    <p className="my-1">Our 2023 shortlisted project 'Harnessing Systrack Data to Protect Government Assets'</p>
                    <p className="my-1">DWP Smarter & Remote working benefits and managing the risks to keep data protected.</p>
                    <p className="my-1">Utilising digital user experience and device performance data for data-backed decision making.</p>
                    <p className="my-1">DWP's future plans to harness the value of data</p>
                    </small>
                  </div>
                </div>
              </AgendaDay>
            </div>
            <div className="col-lg p-2" style={{
              fontFamily: `Din Pro`
            }}>
              <AgendaDay title="Wednesday" day="15" subtitle="February 2023" description="Harnessing the Value of Data" date="15th February 2023" color="#d22c5b" sessionId="2023-02-15">

                <AgendaItem>
                  <AgendaTitle title="Harnessing the Value of Data" />
                </AgendaItem>

                <div className="shadow-sm p-3 mb-3 rounded text-white" style={{ backgroundColor: `#d22c5b` }}>
                  <div>10:00</div>
                  <div className="font-weight-bold">The Principal Tool for Data Evaluation at DWP</div>
                  <div className="row my-3">
                    <div className="col">
                      <div className="rounded-circle m-auto overflow-hidden border" style={{ width: `60px`, height: `60px`, backgroundColor: `rgb(255,255,255,0.6)`}}>
                        <img src={DWPDigitalLogo} className="img-fluid" />
                      </div>
                      <div className="mt-3">DWP Digital</div>
                      {/* <div className="font-weight-lighter"><small>Stakeholder and Approvals Manager, GPA</small></div> */}
                    </div>
                    <div className="col">
                      <div className="rounded-circle m-auto overflow-hidden border" style={{ width: `60px`, height: `60px`, backgroundColor: `rgb(255,255,255,0.6)`}}>
                        <img src={LakesideSoftwareImg} className="img-fluid" />
                      </div>
                      <div className="mt-3">Lakeside Software</div>
                      {/* <div className="font-weight-lighter"><small>Customer Experience Lead, GPA</small></div> */}
                    </div>
                  </div>
                  <hr />
                  <div>
                    <small>
                    <p className="my-1 font-weight-bold">Topics to be covered:</p>
                    <p className="my-1">Our 2023 shortlisted project ‘Harnessing Systrack Data to Protect Government Assets’</p>
                    <p className="my-1">DWP Smarter & Remote working benefits, and the not so obvious data & security risks.</p>
                    <p className="my-1">Identifying where DWP data is, how it’s being put at risk and the methods to address those risks.</p>
                    <p className="my-1">Collecting digital user experience data and device performance data and making this available for analysis on enterprise experience and performance.</p>
                    <p className="my-1">DWP’s future plans to harness the value of data</p>
                    </small>
                  </div>
                </div>
              </AgendaDay>
            </div>
            
          </Row>

          {/* <SectionTitle text="Week 4 Workshops" /> */}
          <Row>
            <div className="col-lg p-2" style={{
                fontFamily: `Din Pro`
              }}>
              <AgendaDay title="Tuesday" day="21" subtitle="February 2023" description="Building Collaborative Communities" date="21st February 2023" color="#ff8c27" sessionId="2023-02-21">
                <AgendaItem>
                  <AgendaTitle title="Building Collaborative Communities" />
                </AgendaItem>

                <div className="shadow-sm p-3 mb-3 rounded text-white" style={{ backgroundColor: `#ff8c27` }}>
                  <div>10:00</div>
                  <div className="font-weight-bold">Putting communities at the heart of our Government Hubs</div>
                  <div className="row my-3">
                    <div className="col">
                      <div className="rounded-circle m-auto overflow-hidden border" style={{ width: `60px`, height: `60px`, backgroundColor: `rgb(255,255,255,0.6)`}}>
                        <img src={LauraChapmanImg} className="img-fluid" />
                      </div>
                      <div className="mt-3">Laura Chapman</div>
                      <div className="font-weight-lighter"><small>Stakeholder and Approvals Manager, GPA</small></div>
                    </div>
                    <div className="col">
                      <div className="rounded-circle m-auto overflow-hidden border" style={{ width: `60px`, height: `60px`, backgroundColor: `rgb(255,255,255,0.6)`}}>
                        <img src={RuthSmethurstImg} className="img-fluid" />
                      </div>
                      <div className="mt-3">Ruth Smethurst</div>
                      <div className="font-weight-lighter"><small>Customer Experience Lead, GPA</small></div>
                    </div>
                  </div>
                  <div className="row my-3">
                    <div className="col">
                      <div className="rounded-circle m-auto overflow-hidden border" style={{ width: `60px`, height: `60px`, backgroundColor: `rgb(255,255,255,0.6)`}}>
                        <img src={ShonaAdamsImg} className="img-fluid" />
                      </div>
                      <div className="mt-3">Shona Adams</div>
                      <div className="font-weight-lighter"><small>Associate Director - Workplace Change, Scottish Futures Trust</small></div>
                    </div>
                  </div>
                  <hr />
                  <div>
                    <small>
                    <p className="my-1 font-weight-bold">Topics to be covered:</p>
                    <p className="my-1">1. Regional pride and connections</p>
                    <p className="my-1">2. Collective sense of ownership in the workplace</p>
                    <p className="my-1">3. Recognising the right behaviours</p>
                    </small>
                  </div>
                </div>

                <div className="shadow-sm p-3 mb-3 rounded text-white" style={{ backgroundColor: `#ff8c27` }}>
                  <div>12:00</div>
                  <div className="font-weight-bold">Communication & Collaboration</div>
                  <div className="row my-3">
                    <div className="col">
                      <div className="rounded-circle m-auto overflow-hidden border" style={{ width: `60px`, height: `60px`, backgroundColor: `rgb(255,255,255,0.6)`}}>
                        <img src={RingCentralLogo} className="img-fluid py-4 px-1 bg-white" />
                      </div>
                      {/* <div className="mt-3">Speaker TBC</div> */}
                      {/* <div className="font-weight-lighter"><small>Deputy Director Corporate Services at No 10 and Cabinet Office Diversity &amp; Inclusion Champion</small></div> */}
                    </div>
                  </div>
                  <hr />
                  <div>
                    <small>
                    <p className="my-1">Essential Tools in Mastering The Hybrid Workplace.</p>
                    </small>
                  </div>
                </div>

                <div className="shadow-sm p-3 mb-3 rounded text-white" style={{ backgroundColor: `#ff8c27` }}>
                    <div>14:00</div>
                    <div className="font-weight-bold">Work in 2023 and Beyond</div>
                    <div className="row my-3">
                      <div className="col">
                        <div className="rounded-circle m-auto overflow-hidden border" style={{ width: `60px`, height: `60px`, backgroundColor: `rgb(255,255,255,0.6)`}}>
                          <img src={ZoomLogo} className="img-fluid py-4 px-2 bg-white" />
                        </div>
                        {/* <div className="mt-3">Speaker TBC</div> */}
                        {/* <div className="font-weight-lighter"><small>Deputy Director Corporate Services at No 10 and Cabinet Office Diversity &amp; Inclusion Champion</small></div> */}
                      </div>
                    </div>
                    <hr />
                    <div>
                      <small>
                      <p className="my-1">Preview the impact new technologies, borderless collaboration, customer experience, and the adoption of automation will have on Public Sector organisations in 2023 and beyond.</p>
                      </small>
                    </div>
                  </div>
              </AgendaDay>
            </div>
            <div className="col-lg p-2" style={{
              fontFamily: `Din Pro`
            }}>
              <AgendaDay title="Wednesday" day="22" subtitle="February 2023" description="Improving Space - Creating a Great Place to Work" date="22nd February 2023" color="#d22c5b" sessionId="2023-02-22">
                <AgendaItem>
                  {/* <AgendaTime time="09:00" color="#ff8c27" /> */}
                  <AgendaTitle title="Improving Spaces - Creating a Great Place to Work" />
                </AgendaItem>

                <div className="shadow-sm p-3 mb-3 rounded text-white" style={{ backgroundColor: `#d22c5b` }}>
                  <div>10:00</div>
                  <div className="font-weight-bold">The link between great design and great places to work</div>
                  <div className="row my-3">
                    <div className="col">
                      <div className="rounded-circle m-auto overflow-hidden border" style={{ width: `60px`, height: `60px`, backgroundColor: `rgb(255,255,255,0.6)`}}>
                        <img src={AidenKearneyImg} className="img-fluid" />
                      </div>
                      <div className="mt-3">Aiden Kearney</div>
                      <div className="font-weight-lighter"><small>Head of Design - Workplace Services Directorate - Project Delivery, GPA</small></div>
                    </div>
                    <div className="col">
                      <div className="rounded-circle m-auto overflow-hidden border" style={{ width: `60px`, height: `60px`, backgroundColor: `rgb(255,255,255,0.6)`}}>
                        <img src={CharlotteDuncanImg} className="img-fluid" />
                      </div>
                      <div className="mt-3">Charlotte Duncan</div>
                      <div className="font-weight-lighter"><small>Workplace Design Manager (ARB/RIBA), GPA</small></div>
                    </div>
                  </div>
                  <div className="row my-3">
                    <div className="col">
                      <div className="rounded-circle m-auto overflow-hidden border" style={{ width: `60px`, height: `60px`, backgroundColor: `rgb(255,255,255,0.6)`}}>
                        <img src={PamRobertsImg} className="img-fluid" />
                      </div>
                      <div className="mt-3">Pamela Roberts</div>
                      <div className="font-weight-lighter"><small>Hd Customer Support Services, DIO</small></div>
                    </div>
                    <div className="col">
                      <div className="rounded-circle m-auto overflow-hidden border" style={{ width: `60px`, height: `60px`, backgroundColor: `rgb(255,255,255,0.6)`}}>
                        <img src={DWImg} className="img-fluid" />
                      </div>
                      <div className="mt-3">Dave W</div>
                      <div className="font-weight-lighter"><small>GCHQ</small></div>
                    </div>
                  </div>
                  <hr />
                  <div>
                    <small>
                    <p className="my-1 font-weight-bold">Topics to be covered:</p>
                    <p className="my-1">1. Inclusive design - allowing everyone to enjoy the benefits of a great place to work</p>
                    <p className="my-1">2. Showcase recent projects with user centred design</p>
                    <p className="my-1">3. How to measure and improve user experiences</p>
                    </small>
                  </div>
                </div>

                <div className="shadow-sm p-3 mb-3 rounded text-white" style={{ backgroundColor: `#d22c5b` }}>
                  <div>12:00</div>
                  <div className="font-weight-bold">What is the purpose of the office?</div>
                  <div className="row my-3">
                    <div className="col">
                      <div className="rounded-circle m-auto overflow-hidden border" style={{ width: `60px`, height: `60px`, backgroundColor: `rgb(255,255,255,0.6)`}}>
                        <img src={TitaAtangImg} className="img-fluid" />
                      </div>
                      <div className="mt-3">Tita Atang</div>
                      <div className="font-weight-lighter"><small>Solution Consultant (Modern Workplace)<br />Bytes Software Services</small></div>
                    </div>
                  </div>
                  <hr />
                  <div>
                    <small>
                    <p className="my-1">Introducing Microsoft Places + Microsoft Viva, optimise your workspaces with connected workplaces and turn your spaces into places.</p>
                    </small>
                  </div>
                </div>

                <div className="shadow-sm p-3 mb-3 rounded text-white" style={{ backgroundColor: `#d22c5b` }}>
                  <div>14:00</div>
                  <div className="font-weight-bold">The interoperable estate</div>
                  <div className="row my-3">
                    <div className="col">
                      <div className="rounded-circle m-auto overflow-hidden border" style={{ width: `60px`, height: `60px`, backgroundColor: `rgb(255,255,255,0.6)`}}>
                        <img src={PolyLogo} className="img-fluid py-4 px-2 bg-white" style={{ marginTop: `-3px` }} />
                      </div>
                      {/* <div className="mt-3">Tita Atang</div> */}
                      {/* <div className="font-weight-lighter"><small>Solution Consultant (Modern Workplace)<br />Bytes Software Services</small></div> */}
                    </div>
                  </div>
                  <hr />
                  <div>
                    <small>
                    <p className="my-1">The ‘future of work’ necessitates a new approach, one that helps people connect to meeting spaces and to one another in considerably more dynamic and engaging ways.</p>
                    </small>
                  </div>
                </div>
              </AgendaDay>
            </div>
            <div className="col-lg p-2" style={{
              fontFamily: `Din Pro`
            }}>
              <AgendaDay title="Thursday" day="23" subtitle="February 2023" description="Digital Transformation: Enabling New Ways of Working" date="23rd February 2023" color="#b000a9" sessionId="2023-02-23">
                <AgendaItem>
                  <AgendaTitle title="Digital Transformation: Enabling New Ways of Working" />
                </AgendaItem>

                <div className="shadow-sm p-3 mb-3 rounded text-white" style={{ backgroundColor: `#b000a9` }}>
                  <div>10:00</div>
                  <div className="font-weight-bold">GPA's vision for technology enabled workplaces</div>
                  <div className="row my-3">
                    <div className="col">
                      <div className="rounded-circle m-auto overflow-hidden border" style={{ width: `60px`, height: `60px`, backgroundColor: `rgb(255,255,255,0.6)`}}>
                        <img src={NeilWilliamsImg} className="img-fluid" />
                      </div>
                      <div className="mt-3">Neil Williams</div>
                      <div className="font-weight-lighter"><small>Chief Digital & Technology Officer, GPA</small></div>
                    </div>
                    <div className="col">
                      <div className="rounded-circle m-auto overflow-hidden border" style={{ width: `60px`, height: `60px`, backgroundColor: `rgb(255,255,255,0.6)`}}>
                        <img src={AlGardnerImg} className="img-fluid" />
                      </div>
                      <div className="mt-3">Al Garnder</div>
                      <div className="font-weight-lighter"><small>Head of Technology, GPA</small></div>
                    </div>
                  </div>
                  <hr />
                  <div>
                    <small>
                    <p className="my-1 font-weight-bold">Topics to be covered:</p>
                    <p className="my-1">1. How tech enables great workspaces</p>
                    <p className="my-1">2. Interoperability</p>
                    </small>
                  </div>
                </div>

                <div className="shadow-sm p-3 mb-3 rounded text-white" style={{ backgroundColor: `#b000a9` }}>
                  <div>12:00</div>
                  <div className="font-weight-bold">Implementing Digital Workflows</div>
                  <div className="row my-3">
                    <div className="col">
                      <div className="rounded-circle m-auto overflow-hidden border" style={{ width: `60px`, height: `60px`, backgroundColor: `rgb(255,255,255,0.6)`}}>
                        <img src={AdobeLogo} className="img-fluid py-3 px-3 bg-white" style={{ marginTop: `-4 px`}} />
                      </div>
                      {/* <div className="mt-3">Speaker TBC</div> */}
                      {/* <div className="font-weight-lighter"><small>Deputy Director Corporate Services at No 10 and Cabinet Office Diversity &amp; Inclusion Champion</small></div> */}
                    </div>
                  </div>
                  <hr />
                  <div>
                    <small>
                    <p className="my-1">Digital Transformation with Adobe and integration with Microsoft Teams, Salesforce, SAP and Service Now</p>
                    </small>
                  </div>
                </div>

                <div className="shadow-sm p-3 mb-3 rounded text-white" style={{ backgroundColor: `#b000a9` }}>
                  <div>14:00</div>
                  <div className="font-weight-bold">Modern Workplace - Power Your People. Anywhere. Anytime!</div>
                  <div className="row my-3">
                    <div className="col">
                      <div className="rounded-circle m-auto overflow-hidden border" style={{ width: `60px`, height: `60px`, backgroundColor: `rgb(255,255,255,0.6)`}}>
                        <img src={BytesLogo} className="img-fluid py-4 px-1 bg-white" />
                      </div>
                      {/* <div className="mt-3">Speaker TBC</div> */}
                      {/* <div className="font-weight-lighter"><small>Deputy Director Corporate Services at No 10 and Cabinet Office Diversity &amp; Inclusion Champion</small></div> */}
                    </div>
                  </div>
                  <hr />
                  <div>
                    <small>
                    <p className="my-1">A Bytes technology masterclass showcasing the art of the possible.</p>
                    </small>
                  </div>
                </div>

                <div className="shadow-sm p-3 mb-3 rounded text-white" style={{ backgroundColor: `#ff8c27` }} hidden>
                    <div className="row my-3">
                      <div className="col">
                        <div className="rounded-circle m-auto overflow-hidden border" style={{ width: `60px`, height: `60px`, backgroundColor: `rgb(255,255,255,0.6)`}}>
                          <img src={HelenLedererImg} className="img-fluid" />
                        </div>
                        <div className="mt-3">Helen Lederer</div>
                        <div className="font-weight-lighter"><small>Deputy Director Corporate Services at No 10 and Cabinet Office Diversity &amp; Inclusion Champion</small></div>
                      </div>
                    </div>
                  </div>
              </AgendaDay>
            </div>
          </Row>

          {/* <SectionTitle text="Smarter Working Live Day" /> */}
          <Row>
            <div className="col-lg p-2" style={{
              fontFamily: `Din Pro`
            }}>
              <AgendaDay title="Wednesday" day="1" subtitle="March 2023" description="Smarter Working Live Day" date="1st March 2023" sessionId="2023-03-01">
                <div className="shadow-sm p-3 mb-3 rounded text-white" style={{ backgroundColor: `#ff8c27` }}>
                  <div>Speakers Include:</div>
                  <div className="row my-3">
                    <div className="col">
                      <div className="rounded-circle m-auto overflow-hidden border" style={{ width: `60px`, height: `60px`, backgroundColor: `rgb(255,255,255,0.6)`}}>
                        <img src={DominicBrankinImg} className="img-fluid" />
                      </div>
                      <div className="mt-3">Dominic Brankin</div>
                      <div className="font-weight-lighter"><small>Director, Workplace Services, GPA</small></div>
                    </div>
                    <div className="col">
                      <div className="rounded-circle m-auto overflow-hidden border" style={{ width: `60px`, height: `60px`, backgroundColor: `rgb(255,255,255,0.6)`}}>
                        <img src={PeterCheeseImg} className="img-fluid" />
                      </div>
                      <div className="mt-3">Peter Cheese</div>
                      <div className="font-weight-lighter"><small>Chief Executive Officer,  CIPD</small></div>
                    </div>
                    <div className="col">
                      <div className="rounded-circle m-auto overflow-hidden border" style={{ width: `60px`, height: `60px`, backgroundColor: `rgb(255,255,255,0.6)`}}>
                        <img src={JasBilenImg} className="img-fluid" />
                      </div>
                      <div className="mt-3">Jas Bilen</div>
                      <div className="font-weight-lighter"><small>Director of People at Ofwat</small></div>
                    </div>
                    <div className="col">
                      <div className="rounded-circle m-auto overflow-hidden border" style={{ width: `60px`, height: `60px`, backgroundColor: `rgb(255,255,255,0.6)`}}>
                        <img src={StevenBoydImg} className="img-fluid" />
                      </div>
                      <div className="mt-3">Steven Boyd</div>
                      <div className="font-weight-lighter"><small>Chief Executive Officer, GPA</small></div>
                    </div>
                  </div>
                  <div className="row my-3">
                    <div className="col">
                      <div className="rounded-circle m-auto overflow-hidden border" style={{ width: `60px`, height: `60px`, backgroundColor: `rgb(255,255,255,0.6)`}}>
                        <img src={PerryTimmsImg} className="img-fluid" />
                      </div>
                      <div className="mt-3">Perry Timms</div>
                      <div className="font-weight-lighter"><small>Founder and Chief Energy Officer</small></div>
                    </div>
                    <div className="col">
                      <div className="rounded-circle m-auto overflow-hidden border" style={{ width: `60px`, height: `60px`, backgroundColor: `rgb(255,255,255,0.6)`}}>
                        <img src={IanEllisonImg} className="img-fluid" />
                      </div>
                      <div className="mt-3">Ian Ellison</div>
                      <div className="font-weight-lighter"><small>Workplace Geeks Podcaster and Director</small></div>
                    </div>
                    <div className="col">
                      <div className="rounded-circle m-auto overflow-hidden border" style={{ width: `60px`, height: `60px`, backgroundColor: `rgb(255,255,255,0.6)`}}>
                        <img src={AnneBurtImg} className="img-fluid" />
                      </div>
                      <div className="mt-3">Anne Burt</div>
                      <div className="font-weight-lighter"><small>Head of Business Change, GPA</small></div>
                    </div>
                    <div className="col">
                      <div className="rounded-circle m-auto overflow-hidden border" style={{ width: `60px`, height: `60px`, backgroundColor: `rgb(255,255,255,0.6)`}}>
                        <img src={JeremyEdwardsImg} className="img-fluid" />
                      </div>
                      <div className="mt-3">Jeremy Edwards</div>
                      <div className="font-weight-lighter"><small>Head of Solutions &amp; Technology, Bytes Software Services</small></div>
                    </div>
                  </div>

                  <div className="row my-3">
                    <div className="col">
                      <div className="rounded-circle m-auto overflow-hidden border" style={{ width: `60px`, height: `60px`, backgroundColor: `rgb(255,255,255,0.6)`}}>
                        <img src={KateGuthrieImg} className="img-fluid" />
                      </div>
                      <div className="mt-3">Kate Guthrie</div>
                      <div className="font-weight-lighter"><small>Deputy Director Workplace Experience and Smarter Working Programme Director, GPA</small></div>
                    </div>
                    <div className="col">
                      <div className="rounded-circle m-auto overflow-hidden border" style={{ width: `60px`, height: `60px`, backgroundColor: `rgb(255,255,255,0.6)`}}>
                        <img src={LauraFaulknerImg} className="img-fluid" />
                      </div>
                      <div className="mt-3">Laura Faulkner</div>
                      <div className="font-weight-lighter"><small>Director of Business Services, Nationwide Building Society</small></div>
                    </div>
                    <div className="col">
                      <div className="rounded-circle m-auto overflow-hidden border" style={{ width: `60px`, height: `60px`, backgroundColor: `rgb(255,255,255,0.6)`}}>
                        <img src={JudithAverisImg} className="img-fluid" />
                      </div>
                      <div className="mt-3">Jude Averis</div>
                      <div className="font-weight-lighter"><small>Smarter Working Business Partner, GPA</small></div>
                    </div>
                    <div className="col">
                      <div className="rounded-circle m-auto overflow-hidden border" style={{ width: `60px`, height: `60px`, backgroundColor: `rgb(255,255,255,0.6)`}}>
                        <img src={ClaireStoreyImg} className="img-fluid" />
                      </div>
                      <div className="mt-3">Claire Storey</div>
                      <div className="font-weight-lighter"><small>Smarter Working Business Partner, GPA</small></div>
                    </div>
                  </div>

                  <div className="row my-3">
                    <div className="col">
                      <div className="rounded-circle m-auto overflow-hidden border" style={{ width: `60px`, height: `60px`, backgroundColor: `rgb(255,255,255,0.6)`}}>
                        <img src={NicolaYoungImg} className="img-fluid" />
                      </div>
                      <div className="mt-3">Nicola Young</div>
                      <div className="font-weight-lighter"><small>Programme Manager, HMRC</small></div>
                    </div>
                    <div className="col">
                      <div className="rounded-circle m-auto overflow-hidden border" style={{ width: `60px`, height: `60px`, backgroundColor: `rgb(255,255,255,0.6)`}}>
                        <img src={KetanPatelImg} className="img-fluid" />
                      </div>
                      <div className="mt-3">Ketan Patel</div>
                      <div className="font-weight-lighter"><small>DDirector, Defence, MoD</small></div>
                    </div>
                    <div className="col">
                      <div className="rounded-circle m-auto overflow-hidden border" style={{ width: `60px`, height: `60px`, backgroundColor: `rgb(255,255,255,0.6)`}}>
                        <img src={GrantSmithImg} className="img-fluid" />
                      </div>
                      <div className="mt-3">Grant smith</div>
                      <div className="font-weight-lighter"><small>Smarter Working Lead, HMRC</small></div>
                    </div>
                    <div className="col">
                      <div className="rounded-circle m-auto overflow-hidden border" style={{ width: `60px`, height: `60px`, backgroundColor: `rgb(255,255,255,0.6)`}}>
                        <img src={AndrewGoodmanImg} className="img-fluid" />
                      </div>
                      <div className="mt-3">Andrew Goodman</div>
                      <div className="font-weight-lighter"><small>Workplace Transformation Director, DWP</small></div>
                    </div>
                  </div>
                </div>

                <AgendaItem>
                  <AgendaTime time="09:30" color="#ff8c27" />
                  <AgendaTitle title="Registration and Networking" />
                </AgendaItem>

                <AgendaItem>
                  <AgendaTime time="10:00 to 11:00" color="#ff8c27" />
                  <AgendaTitle title="Welcome and Introduction to the day" />
                  <div className="d-flex justify-content-center">
                    <div className="rounded-circle m-2 overflow-hidden border" style={{ width: `40px`, height: `40px`, backgroundColor: `rgb(255,255,255,0.6)`}}>
                      <img src={DominicBrankinImg} className="img-fluid" />
                    </div>
                    <div className="rounded-circle m-2 overflow-hidden border" style={{ width: `40px`, height: `40px`, backgroundColor: `rgb(255,255,255,0.6)`}}>
                      <img src={JeremyEdwardsImg} className="img-fluid" />
                    </div>
                  </div>
                  <AgendaDescription>
                    <p>Settle yourselves in and get ready to take part in the Smarter Working Live 2023 virtual conference.</p>
                    <p>Dominic Brankin (Workplace Services Director, GPA) and Jeremey Edwards (Head of Solutions & Technology, Bytes Software Services) will open the event and review what's in store for the day ahead.</p>
                    <p>Attendees will also get a chance to review and vote on some of the audience led agenda items for the event so make sure you attend this part of the event to have your say.</p>
                  </AgendaDescription>
                </AgendaItem>

                {/* <div className="shadow-sm p-3 mb-3 rounded text-white" style={{ backgroundColor: `#ff8c27` }}>
                  <div className="font-weight-bold">Conference Opener</div>
                  <hr />
                  <div>
                    <small>
                    <p className="my-1">Speech from Dom</p>
                    </small>
                  </div>
                </div> */}

                {/* <div className="shadow-sm p-3 mb-3 rounded text-white" style={{ backgroundColor: `#ff8c27` }}>
                  <div className="font-weight-bold">Bytes Welcome</div>
                  <hr />
                  <div>
                    <small>
                    <p className="my-1">What bytes are going to talk about</p>
                    </small>
                  </div>
                </div> */}

                {/* <div className="shadow-sm p-3 mb-3 rounded text-white" style={{ backgroundColor: `#ff8c27` }}>
                  <div className="font-weight-bold">How to get the best out of today</div>
                  <hr />
                  <div>
                    <small>
                    <p className="my-1">Your chance to shape the agenda</p>
                    </small>
                  </div>
                </div> */}

                <AgendaItem>
                  <AgendaTime time="11:00 to 11:45" color="#ff8c27" />
                  <div className="pb-4">
                  <AgendaTitle title="Live Lounges" />
                  <AgendaDescription>The smarter working live lounges are the place to be if you want an up close and personal view of workplace experience from our thought leaders in this space.</AgendaDescription>
                  </div>

                  <Row>
                    <div className="col">
                      <div className="shadow-sm p-3 mb-3 rounded text-white" style={{ backgroundColor: `#b000a9` }}>
                        <div className="font-weight-bold">What impact does smarter working have on workplace experiences?</div>
                        <div className="d-flex justify-content-center">
                          <div className="rounded-circle m-2 overflow-hidden border" style={{ width: `40px`, height: `40px`, backgroundColor: `rgb(255,255,255,0.6)`}}>
                            <img src={AnneBurtImg} className="img-fluid" />
                          </div>
                          <div className="rounded-circle m-2 overflow-hidden border" style={{ width: `40px`, height: `40px`, backgroundColor: `rgb(255,255,255,0.6)`}}>
                            <img src={IanEllisonImg} className="img-fluid" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col">
                      <div className="shadow-sm p-3 mb-3 rounded text-white" style={{ backgroundColor: `#b000a9` }}>
                        <div className="font-weight-bold">Smarter Working maturity - experiences and lessons learned from the Civil Service Smarter Working programme.</div>
                        <div className="d-flex justify-content-center">
                          <div className="rounded-circle m-2 overflow-hidden border" style={{ width: `40px`, height: `40px`, backgroundColor: `rgb(255,255,255,0.6)`}}>
                            <img src={JudithAverisImg} className="img-fluid" />
                          </div>
                          <div className="rounded-circle m-2 overflow-hidden border" style={{ width: `40px`, height: `40px`, backgroundColor: `rgb(255,255,255,0.6)`}}>
                            <img src={NicolaYoungImg} className="img-fluid" />
                          </div>
                          <div className="rounded-circle m-2 overflow-hidden border" style={{ width: `40px`, height: `40px`, backgroundColor: `rgb(255,255,255,0.6)`}}>
                            <img src={ClaireStoreyImg} className="img-fluid" />
                          </div>
                          <div className="rounded-circle m-2 overflow-hidden border" style={{ width: `40px`, height: `40px`, backgroundColor: `rgb(255,255,255,0.6)`}}>
                            <img src={KetanPatelImg} className="img-fluid" />
                          </div>
                          <div className="rounded-circle m-2 overflow-hidden border" style={{ width: `40px`, height: `40px`, backgroundColor: `rgb(255,255,255,0.6)`}}>
                            <img src={GrantSmithImg} className="img-fluid" />
                          </div>
                          <div className="rounded-circle m-2 overflow-hidden border" style={{ width: `40px`, height: `40px`, backgroundColor: `rgb(255,255,255,0.6)`}}>
                            <img src={AndrewGoodmanImg} className="img-fluid" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </Row>
                </AgendaItem>

                {/* <div className="shadow-sm p-3 mb-3 rounded text-white" style={{ backgroundColor: `#ff8c27` }}>
                  <div>Room 1</div>
                  <div className="font-weight-bold">Smarter Working, so what?</div>
                </div> */}

                {/* <div className="shadow-sm p-3 mb-3 rounded text-white" style={{ backgroundColor: `#ff8c27` }}>
                  <div>Room 2</div>
                  <div className="font-weight-bold">Smarter Working and Change</div>
                </div> */}

                {/* <div className="shadow-sm p-3 mb-3 rounded text-white" style={{ backgroundColor: `#ff8c27` }}>
                  <div>Room 3</div>
                  <div className="font-weight-bold">In conversation with Smarter Working mature organisations</div>
                </div> */}

                <AgendaItem>
                  <AgendaTime time="12:00 to 13:00" color="#ff8c27" />
                  <AgendaTitle title="Hackathon" />
                  <div className="row pt-3">
                    <div className="col">
                      <div className="rounded-circle m-auto overflow-hidden border" style={{ width: `40px`, height: `40px`, backgroundColor: `rgb(255,255,255,0.6)`}}>
                        <img src={PerryTimmsImg} className="img-fluid" />
                      </div>
                    </div>
                  </div>
                  <br />
                  <AgendaDescription>
                  <p>Join us for an hour of fast paced innovation and action led learning. We will be hacking one of the smarter working pillars (People and Culture, Leadership, Technology or workplace) as chosen by you.</p>
                  <p className="font-weight-bold">What to expect:</p>
                  <p>You'll have the chance to get under the skin of what the challenges are of the hack topic and what we as a community can do about this.</p>
                  <p className="font-weight-bold">What format will the hackathon take:</p>
                  <p>You'll be given an introduction into the chosen topic to hack and how this will be done. You’ll then be assigned to a smaller virtual breakout group made up of your fellow conference attendees. Your group facilitator will talk you through the hack process where you and your group will identify the issue and possible actions that can tackle it.</p>
                  <p className="font-weight-bold">What do I need to do?</p>
                  <p>Put simply, take part! You will get a lot out of the hackathon if you are an active participant. So you need to be willing to contribute your knowledge and experience to help drive the discussion forward.</p>
                  </AgendaDescription>
                </AgendaItem>

                <AgendaItem>
                  <AgendaTime time="13:30 to 14:00" color="#ff8c27" />
                  <AgendaTitle title="Panel 1 - Returning to workspaces, what are we learning?" />
                  <div className="d-flex justify-content-center">
                    <div className="rounded-circle m-2 overflow-hidden border" style={{ width: `40px`, height: `40px`, backgroundColor: `rgb(255,255,255,0.6)`}}>
                      <img src={KateGuthrieImg} className="img-fluid" />
                    </div>
                    <div className="rounded-circle m-2 overflow-hidden border" style={{ width: `40px`, height: `40px`, backgroundColor: `rgb(255,255,255,0.6)`}}>
                      <img src={LauraFaulknerImg} className="img-fluid" />
                    </div>
                  </div>
                  <AgendaDescription>
                    <p>The theme of this panel discussion is 'returning to workspaces, what are we learning' but the exact question they will respond to will be chosen by conference attendees. In an ‘unconference’ format our experts on workplace experience are primed and ready to share their experience, knowledge and advice on current workplace experiences.</p>
                  </AgendaDescription>
                </AgendaItem>

                <AgendaItem>
                  <AgendaTime time="14:15 to 14:45" color="#ff8c27" />
                  <AgendaTitle title="Panel 2 - Does anyone know what the future of work will be?" />
                  <div className="d-flex justify-content-center">
                    <div className="rounded-circle m-2 overflow-hidden border" style={{ width: `40px`, height: `40px`, backgroundColor: `rgb(255,255,255,0.6)`}}>
                      <img src={JasBilenImg} className="img-fluid" />
                    </div>
                    <div className="rounded-circle m-2 overflow-hidden border" style={{ width: `40px`, height: `40px`, backgroundColor: `rgb(255,255,255,0.6)`}}>
                      <img src={StevenBoydImg} className="img-fluid" />
                    </div>
                    <div className="rounded-circle m-2 overflow-hidden border" style={{ width: `40px`, height: `40px`, backgroundColor: `rgb(255,255,255,0.6)`}}>
                      <img src={PeterCheeseImg} className="img-fluid" />
                    </div>
                  </div>

                  <AgendaDescription>
                    <p>The theme of this panel discussion is 'does anyone know what the future of work will be' but the exact question they will respond to will be chosen by conference attendees. In an ‘unconference’ format our experts on workplace experience are primed and ready to share their experience, knowledge and advice on this horizon scanning topic.</p>
                  </AgendaDescription>
                </AgendaItem>

                <AgendaItem>
                  <AgendaTime time="15:00 to 15:15" color="#ff8c27" />
                  <AgendaTitle title="Q&A with panel members" />
                </AgendaItem>

                <AgendaItem>
                  <AgendaTime time="15:15 to 15:30" color="#ff8c27" />
                  <AgendaTitle title="Closing remarks" />
                  <div className="d-flex justify-content-center">
                    <div className="rounded-circle m-2 overflow-hidden border" style={{ width: `40px`, height: `40px`, backgroundColor: `rgb(255,255,255,0.6)`}}>
                      <img src={DominicBrankinImg} className="img-fluid" />
                    </div>
                  </div>
                </AgendaItem>

                {/* <div className="shadow-sm p-3 mb-3 rounded text-white" style={{ backgroundColor: `#ff8c27` }}>
                  <div>13:30 to 14:00</div>
                  <div className="font-weight-bold">Panel 1 - Returning to workspaces, what we're learning?</div>
                  <hr />
                  <div>
                    <small>
                    <p className="my-1">1. What do we know about the experiences people are having in workspaces at the moment?</p>
                    <p className="my-1">2. How are diffent types of workers adjusting?</p>
                    <p className="my-1">3. Behavioural change - the impact it has/what good looks like</p>
                    </small>
                  </div>
                </div> */}

                {/* <div className="shadow-sm p-3 mb-3 rounded text-white" style={{ backgroundColor: `#ff8c27` }}>
                  <div>14:15 to 14:45</div>
                  <div className="font-weight-bold">Panel 2 - Does anyone know what the future of work will be?</div>
                  <hr />
                  <div>
                    <small>
                    <p className="my-1">1. What does the future workforce want from work?</p>
                    <p className="my-1">2. How we expect workspaces to be used in the future?</p>
                    <p className="my-1">3. Does where we work from really matter?</p>
                    </small>
                  </div>
                </div> */}

                {/* <div className="shadow-sm p-3 mb-3 rounded text-white" style={{ backgroundColor: `#ff8c27` }}>
                  <div>15:00 to 15:15</div>
                  <div className="font-weight-bold">Q&A with panel members</div>
                </div> */}

                {/* <div className="shadow-sm p-3 mb-3 rounded text-white" style={{ backgroundColor: `#ff8c27` }}>
                  <div>15:15 to 15:30</div>
                  <div className="font-weight-bold">Closing remarks</div>
                </div> */}
              </AgendaDay>
            </div>
          </Row>

          <p className="text-center text-white mt-5 mb-0"><small>* Timings and agenda is subject to change *</small></p>

          <div hidden>
            <Row>
              <div className="col-lg p-2" style={{
                fontFamily: `Din Pro`
              }}>

                <AgendaDay title="Wednesday" day="1" subtitle="February 2023">

                  <AgendaItem>
                    <AgendaTime time="09:00" color="#ff8c27" />
                    <AgendaTitle title="Event Start" />
                  </AgendaItem>

                  <div className="shadow-sm p-3 mb-3 rounded text-white" style={{ backgroundColor: `#ff8c27` }}>
                    <div>From 09:30</div>
                    <div className="font-weight-bold">Keynote Sessions</div>
                  </div>

                  <div className="shadow-sm p-3 mb-3 rounded text-white" style={{ backgroundColor: `#ff8c27` }}>
                    <div className="row my-3">
                      <div className="col">
                        <div className="rounded-circle m-auto overflow-hidden border" style={{ width: `60px`, height: `60px`, backgroundColor: `rgb(255,255,255,0.6)`}}>
                          <ImgSBoydProfile />
                        </div>
                        <div className="mt-3">Steven Boyd</div>
                        <div><small>Chief Executive<br />Government Property Agency</small></div>
                      </div>
                    </div>
                  </div>

                  <div className="shadow-sm p-3 mb-3 rounded text-white" style={{ backgroundColor: `#ff8c27` }}>
                    <div className="row my-3">
                      <div className="col">
                        <div className="rounded-circle m-auto overflow-hidden border" style={{ width: `60px`, height: `60px`, backgroundColor: `rgb(255,255,255,0.6)`}}>
                          <img src={HelenLedererImg} className="img-fluid" />
                        </div>
                        <div className="mt-3">Helen Lederer</div>
                        <div className="font-weight-lighter"><small>Deputy Director Corporate Services at No 10 and Cabinet Office Diversity &amp; Inclusion Champion</small></div>
                      </div>
                    </div>
                  </div>

                  <div className="shadow-sm p-3 mb-3 rounded text-white" style={{ backgroundColor: `#ff8c27` }}>
                    <div className="row my-3">
                      <div className="col">
                        <div className="rounded-circle m-auto overflow-hidden border" style={{ width: `60px`, height: `60px`, backgroundColor: `rgb(255,255,255,0.6)`}}>
                          <ImgRMcneilProfile />
                        </div>
                        <div className="mt-3">Rupert McNeil</div>
                        <div className="font-weight-lighter"><small>Chief People Officer<br />Cabinet Office</small></div>
                      </div>
                    </div>
                  </div>

                  <div className="shadow-sm p-3 mb-3 rounded text-white" style={{ backgroundColor: `#ff8c27` }}>
                    <div className="row my-3">
                      <div className="col">
                        <div className="rounded-circle m-auto overflow-hidden border" style={{ width: `60px`, height: `60px`, backgroundColor: `rgb(255,255,255,1)`}}>
                          <img src={JoannaDavinsonImg} className="img-fluid" />
                        </div>
                        <div className="mt-3">Joanna Davinson</div>
                        <div className="font-weight-lighter"><small>Executive Director Central Digital and Data Office<br />Cabinet Office</small></div>
                      </div>
                    </div>
                  </div>

                  {/* <div className="shadow-sm p-3 mb-3 rounded text-white" style={{ backgroundColor: `#ff8c27` }}>
                    <div className="font-weight-bold">More to be announced</div>
                  </div> */}

                  <AgendaItem>
                    <AgendaTitle title="More to be announced" />
                  </AgendaItem>

                  <div className="shadow-sm p-3 mb-3 rounded text-white" style={{ backgroundColor: `#ff8c27` }}>
                    <div>10:30</div>
                    <div className="font-weight-bold">Plenary Hangout</div>
                    <div className="row mt-4 mb-3">
                      <div className="col border-right">
                        <div className="rounded-circle m-auto overflow-hidden border" style={{ width: `60px`, height: `60px`, backgroundColor: `rgb(255,255,255,0.6)`}}>
                          <ImgDBrankinProfile />
                        </div>
                        <div className="mt-3">Dominic Brankin</div>
                        <div><small>Director, Workplace Services<br />GPA</small></div>
                      </div>
                      <div className="col border-left">
                        <div className="rounded-circle m-auto" style={{ width: `60px`, height: `60px`, backgroundColor: `rgb(255,255,255,0.6)`}}>
                          <div style={{padding: `16px`}}><FaPlus size={28} /></div>
                        </div>
                        <div className="mt-3">Special Guests</div>
                        {/* <div className="font-weight-lighter">Job Title</div>
                        <div>Organsiation</div> */}
                      </div>
                    </div>
                  </div>

                  <div className="shadow-sm p-3 mb-3 bg-white rounded font-weight-bold">
                    <div style={{ color: `#ff8c27` }}>11:30</div>
                    <div className="text-center" style={{ color: `#929292` }}>Networking &amp; Exhibition</div>
                  </div>

                  <div className="shadow-sm p-3 mb-3 rounded text-white" style={{ backgroundColor: `#ff8c27` }}>
                    <div>12:15</div>
                    <div className="font-weight-bold">Is Your IT Strategy Ready for the Hybrid Workplace?</div>
                    <div className="row mt-4 mb-3">
                      <div className="col border-right">
                        <div className="rounded-circle m-auto overflow-hidden border" style={{ width: `60px`, height: `60px`, backgroundColor: `rgb(255,255,255,0.6)`}}>
                          <img src={MarkBrunetteImg} className="img-fluid" />
                        </div>
                        <div className="mt-3">Mark Brunette</div>
                        <div><small><span style={{opacity: `0.7`}}>Senior Infrastructure Engineer</span><br />DWP</small></div>
                      </div>
                      <div className="col border-left">
                        <div className="rounded-circle m-auto overflow-hidden border" style={{ width: `60px`, height: `60px`, backgroundColor: `rgb(255,255,255,0.6)`}}>
                          <img src={DanUnsworthImg} className="img-fluid" />
                        </div>
                        <div className="mt-3">Dan Unsworth</div>
                        <div><small><span style={{opacity: `0.7`}}>Infrastructure Engineer</span><br />DWP</small></div>
                      </div>
                    </div>
                    <div className="row mt-4 mb-3">
                      <div className="col border-right">
                        <div className="rounded-circle m-auto overflow-hidden border" style={{ width: `60px`, height: `60px`, backgroundColor: `rgb(255,255,255,0.6)`}}>
                          <img src={NickMcIntoshImg} className="img-fluid" />
                        </div>
                        <div className="mt-3">Nick Mcintosh</div>
                        <div><small><span style={{opacity: `0.7`}}>Account Manager</span><br />Lakeside Software</small></div>
                      </div>
                      <div className="col border-left">
                        <div className="rounded-circle m-auto overflow-hidden border" style={{ width: `60px`, height: `60px`, backgroundColor: `rgb(255,255,255,0.6)`}}>
                          <img src={RobinSouthallImg} className="img-fluid" />
                        </div>
                        <div className="mt-3">Robin Southall</div>
                        <div><small><span style={{opacity: `0.7`}}>Global SI Team Lead for Customer Success</span><br />Lakeside Software</small></div>
                      </div>
                    </div>
                    <button class="btn btn-light btn-sm" type="button" data-toggle="collapse" data-target="#lakesideOne" aria-expanded="false" aria-controls="lakesideOne">
                      Read More
                    </button>
                    <div class="collapse" id="lakesideOne">
                      <div class="text-left text-white pt-3">
                        <small>
                        <p>We used to work at the office. Now we work in a thousand offices. Each one is different. Delivering a consistent, optimal employee experience in all these different environments will be the defining organizational challenge for businesses this decade.</p>
                        <p>Organizations rushed to adapt to the limitations of home networks and bandwidth, not to mention a spike in help desk requests and infrastructure that sits out of the control of IT.</p>
                        <p className="mb-0">So how do we overcome these new workspace challenges? In this workshop, Lakeside Software and DWP will explore how to support employees with best possible digital experience, no matter where they work. We’ll discuss L1 Remote Support and moving 80,000 staff with very little tooling, the impact it’s had on the end user and the L1 Engineer. We will also discuss EUC performance and how DWP was able to measure, score and monitor device performance across it’s remote workers in 900 offices and the positive impacts that it had.</p>
                        </small>
                      </div>
                    </div>
                  </div>

                  <div className="shadow-sm p-3 mb-3 rounded text-white" style={{ backgroundColor: `#ff8c27` }}>
                    <div>12:15</div>
                    <div className="font-weight-bold">Digital Fitness &amp; Your Journey To Smarter Services</div>
                    <div className="row mt-4 mb-3">
                      <div className="col border-right">
                        <div className="rounded-circle m-auto overflow-hidden border" style={{ width: `60px`, height: `60px`, backgroundColor: `rgb(255,255,255,0.6)`}}>
                          <img src={TobyHaylesImg} className="img-fluid" />
                        </div>
                        <div className="mt-3">Toby Hayles</div>
                        <div><small><span style={{opacity: `0.7`}}>Director EMEA, Enterprise Accounts</span><br />Nintex</small></div>
                      </div>
                      <div className="col border-left">
                        <div className="rounded-circle m-auto overflow-hidden border" style={{ width: `60px`, height: `60px`, backgroundColor: `rgb(255,255,255,0.6)`}}>
                          <img src={JamesWatkinsImg} className="img-fluid" />
                        </div>
                        <div className="mt-3">James Watkins</div>
                        <div><small><span style={{opacity: `0.7`}}>Public Sector Lead UK&amp;I</span><br />Nintex</small></div>
                      </div>
                    </div>
                    <button class="btn btn-light btn-sm" type="button" data-toggle="collapse" data-target="#nintexOne" aria-expanded="false" aria-controls="nintexOne">
                      Read More
                    </button>
                    <div class="collapse" id="nintexOne">
                      <div class="text-left text-white pt-3">
                        <small>
                        <p className="mb-0">Public Sector organisations, large and small, now grapple with remote work productivity. Many find themselves at a crossroads: pursue a pre-pandemic way of doing things or take this opportunity to evaluate workflows and reduce employee’s efforts spent on mundane and repetitive tasks.  Hear the learnings and three key takeaways from Nintex who provide process management and automation software solutions and have helped judge the awards in this category.</p>
                        </small>
                      </div>
                    </div>
                  </div>

                  <div className="shadow-sm p-3 mb-3 bg-white rounded font-weight-bold">
                    <div style={{ color: `#ff8c27` }}>12:45</div>
                    <div className="text-center" style={{ color: `#929292` }}>Networking &amp; Exhibition</div>
                  </div>

                  <div className="shadow-sm p-3 mb-3 rounded text-white" style={{ backgroundColor: `#ff8c27` }}>
                    <div>13:30</div>
                    <div className="font-weight-bold">The Path to Digitising UK Central Government​</div>
                    <div className="row mt-4 mb-3">
                      <div className="col border-right">
                        <div className="rounded-circle m-auto overflow-hidden border" style={{ width: `60px`, height: `60px`, backgroundColor: `rgb(255,255,255,0.6)`}}>
                          <img src={JennyBaynesImg} className="img-fluid" />
                        </div>
                        <div className="mt-3">Jenny Baynes</div>
                        <div><small><span style={{opacity: `0.7`}}>Head of EPM &amp; Business Improvement (Digital services)</span><br />Highways England</small></div>
                      </div>
                      <div className="col border-left">
                        <div className="rounded-circle m-auto overflow-hidden border" style={{ width: `60px`, height: `60px`, backgroundColor: `rgb(255,255,255,0.6)`}}>
                          <img src={SimonShepherdImg} className="img-fluid" />
                        </div>
                        <div className="mt-3">Simon Shepherd</div>
                        <div><small><span style={{opacity: `0.7`}}>Head of Public Sector UK&amp;I</span><br />Adobe</small></div>
                      </div>
                    </div>
                    <button class="btn btn-light btn-sm" type="button" data-toggle="collapse" data-target="#adobeThree" aria-expanded="false" aria-controls="adobeThree">
                      Read More
                    </button>
                    <div class="collapse" id="adobeThree">
                      <div class="text-left text-white pt-3">
                        <small>
                        <p>Government departments increasingly face pressure from citizens to deliver seamless digital interactions. And yet why are many still struggling to go fully digital? From Home Office and HMRC to Policing, Prison and Probation Services, it is crucial to have seamless back-office process and drive efficiencies at every touchpoint, and thus driving improved citizen experiences.​</p>
                        <p className="mb-0">Join this session to find out how government departments are overcoming challenges stopping them to embrace digital and the extent to which they have digitally transformed.​</p>
                        </small>
                      </div>
                    </div>
                  </div>

                  <div className="shadow-sm p-3 mb-3 rounded text-white" style={{ backgroundColor: `#ff8c27` }}>
                    <div>13:30</div>
                    <div className="font-weight-bold">Reducing Workload &amp; Case Management Backlogs With Smarter Working</div>
                    <div className="row mt-4 mb-3">
                      <div className="col">
                        <div className="rounded-circle m-auto overflow-hidden border" style={{ width: `60px`, height: `60px`, backgroundColor: `rgb(255,255,255,0.6)`}}>
                          <img src={VijayMagonImg} className="img-fluid" />
                        </div>
                        <div className="mt-3">Dr Vijay Magon</div>
                        <div><small><span style={{opacity: `0.7`}}>Managing Director</span><br />CCube Solutions</small></div>
                      </div>
                    </div>
                    <button class="btn btn-light btn-sm" type="button" data-toggle="collapse" data-target="#ccubeTwo" aria-expanded="false" aria-controls="ccubeTwo">
                      Read More
                    </button>
                    <div class="collapse" id="ccubeTwo">
                      <div class="text-left text-white pt-3">
                        <small>
                        <p>Citizen &amp; Patient services are significantly affected by the growing backlog caused by C-19, with polling consistently showing waiting times to the public are one of the most common reasons for dissatisfaction. How can digital technologies & smarter working help to reduce these backlogs, minimise face-to-face contact to protect customers and staff, and limit the risk of further infections. In this session we explore Smart Digital Records & Case Management in current healthcare settings, whilst sharing best practice that can be applied across the public sector:</p>
                        <ul>
                          <li>Minimise handling of physical notes and records and ensure that important case information is available at the point of need;</li>
                          <li>Support remote working and virtual engagements - seen as crucial for reducing unnecessary calls/visits, saving time and money for the organisation</li>
                          <li>Ensure that delivery of services (in this case example ‘Care Services to non-Covid patients’) can continue, to help avoid issues in the future and minimise revenue losses</li>
                          <li>Improve citizen/patient engagement in the delivery of their services (NHS Example of Patient Initiated Follow Up)</li>
                        </ul>
                        </small>
                      </div>
                    </div>
                  </div>

                  <div className="shadow-sm p-3 mb-3 bg-white rounded font-weight-bold">
                    <div style={{ color: `#ff8c27` }}>14:00</div>
                    <div className="text-center" style={{ color: `#929292` }}>Networking &amp; Exhibition</div>
                  </div>

                  <div className="shadow-sm p-3 mb-3 rounded text-white" style={{ backgroundColor: `#ff8c27` }}>
                    <div>14:45</div>
                    <div className="font-weight-bold">Using insights to design the future workplace</div>
                    <div className="row mt-4 mb-3">
                      <div className="col border-right">
                        <div className="rounded-circle m-auto overflow-hidden border" style={{ width: `60px`, height: `60px`, backgroundColor: `rgb(255,255,255,0.6)`}}>
                          <img src={AndrewNelsonImg} className="img-fluid" />
                        </div>
                        <div className="mt-3">Andrew Nelson</div>
                        <div><small><span style={{opacity: `0.7`}}>Government Property Agency - Workplace and Inclusive Design Manager</span><br />GPA</small></div>
                      </div>
                      <div className="col border-left">
                        <div className="rounded-circle m-auto overflow-hidden border" style={{ width: `60px`, height: `60px`, backgroundColor: `rgb(255,255,255,0.6)`}}>
                          <img src={LeahJonesImg} className="img-fluid" />
                        </div>
                        <div className="mt-3">Leah Jones</div>
                        <div><small><span style={{opacity: `0.7`}}>Government Property Agency - Smarter Working Programme Manager &amp; Head of Customer Insight</span><br />GPA</small></div>
                      </div>
                    </div>
                    <button class="btn btn-light btn-sm" type="button" data-toggle="collapse" data-target="#gpaOne" aria-expanded="false" aria-controls="gpaOne">
                      Read More
                    </button>
                    <div class="collapse" id="gpaOne">
                      <div class="text-left text-white pt-3">
                        <small>
                          <p className="mb-0">Join Andrew Nelson and Leah Jones from the Government Property Agency for a deeper dive into how Covid-19 and the insights that we have gathered have impacted the design of the workplace. This session will also showcase the newly released Version 2 of the Government Workplace Design Guide.</p>
                        </small>
                      </div>
                    </div>
                  </div>

                  <div className="shadow-sm p-3 mb-3 rounded text-white" style={{ backgroundColor: `#ff8c27` }}>
                    <div>14:45</div>
                    <div className="font-weight-bold">Smarter Working Maturity - Showcasing Best Practice</div>
                    <div className="row mt-4 mb-3">
                      <div className="col border-right">
                        <div className="rounded-circle m-auto" style={{ width: `60px`, height: `60px`, backgroundColor: `rgb(255,255,255,0.6)`}}>
                          <div style={{padding: `16px`}}><FaUser size={28} /></div>
                        </div>
                        <div className="mt-3">Richard Alderman</div>
                        <div><small><span style={{opacity: `0.7`}}>Deputy Director, Property and Security</span><br />Department of Health and Social Care</small></div>
                      </div>
                      <div className="col border-left">
                        <div className="rounded-circle m-auto overflow-hidden border" style={{ width: `60px`, height: `60px`, backgroundColor: `rgb(255,255,255,0.6)`}}>
                          <img src={SteveDowneyImg} className="img-fluid" />
                        </div>
                        <div className="mt-3">Steve Downey</div>
                        <div><small><span style={{opacity: `0.7`}}>Head of Workplace and Culture</span><br />National Savings and Investments</small></div>
                      </div>
                    </div>
                    <button class="btn btn-light btn-sm mt-2" type="button" data-toggle="collapse" data-target="#gpaTwo" aria-expanded="false" aria-controls="gpaTwo">
                      Read More
                    </button>
                    <div class="collapse" id="gpaTwo">
                      <div class="text-left text-white pt-3">
                        <small>
                          <p className="mb-0">Join a live panel of Smarter Working practitioners from different government departments who will cover their vision and approaches to smarter working implementation, the lessons learnt, the benefits of smarter working to their organisation, and more.</p>
                        </small>
                      </div>
                    </div>
                  </div>

                  <div className="shadow-sm p-3 mb-3 bg-white rounded font-weight-bold">
                    <div style={{ color: `#ff8c27` }}>15:15</div>
                    <div className="text-center" style={{ color: `#929292` }}>Networking &amp; Exhibition</div>
                  </div>

                  <div className="shadow-sm p-3 mb-3 bg-white rounded font-weight-bold">
                    <div style={{ color: `#ff8c27` }}>17:00</div>
                    <div className="text-center" style={{ color: `#929292` }}>Event Close</div>
                  </div>

                  <p className="text-center text-muted mb-0"><small>* Agenda is subject to change *</small></p>
                </AgendaDay>
              </div>
              <div className="col-lg p-2" style={{
                fontFamily: `Din Pro`
              }}>
                <AgendaDay title="Thursday" day="2" subtitle="February 2023">

                  <div className="shadow-sm p-3 mb-3 bg-white rounded font-weight-bold">
                    <div style={{ color: `#B000A9` }}>09:00</div>
                    <div className="text-center" style={{ color: `#929292` }}>Event Start</div>
                  </div>

                  <div className="shadow-sm p-3 mb-3 rounded text-white" style={{ backgroundColor: `#d22c5b` }}>
                    <div>From 09:30</div>
                    <div className="font-weight-bold">Keynote Sessions</div>
                  </div>

                  <AgendaItem>
                    <AgendaTitle title="To be announced" />
                  </AgendaItem>

                  <div className="shadow-sm p-3 mb-3 rounded text-white" style={{ backgroundColor: `#d22c5b` }}>
                    <div>10:30</div>
                    <div className="font-weight-bold">Plenary Hangout</div>
                    <div className="row mt-4 mb-3" hidden>
                      <div className="col border-right">
                        <div className="rounded-circle m-auto overflow-hidden border" style={{ width: `60px`, height: `60px`, backgroundColor: `rgb(255,255,255,0.6)`}}>
                          <ImgDBrankinProfile />
                        </div>
                        <div className="mt-3">Dominic Brankin</div>
                        <div><small>Director, Workplace Services<br />GPA</small></div>
                      </div>
                      <div className="col border-left">
                        <div className="rounded-circle m-auto" style={{ width: `60px`, height: `60px`, backgroundColor: `rgb(255,255,255,0.6)`}}>
                          <div style={{padding: `16px`}}><FaPlus size={28} /></div>
                        </div>
                        <div className="mt-3">Special Guests</div>
                      </div>
                    </div>
                  </div>

                  <div className="shadow-sm p-3 mb-3 bg-white rounded font-weight-bold">
                    <div style={{ color: `#d22c5b` }}>11:30</div>
                    <div className="text-center" style={{ color: `#929292` }}>Networking &amp; Exhibition</div>
                  </div>

                  <div className="shadow-sm p-3 mb-3 rounded text-white" style={{ backgroundColor: `#d22c5b` }}>
                    <div>12:15</div>
                    <div className="font-weight-bold">Workshops</div>
                  </div>

                  <div className="shadow-sm p-3 mb-3 rounded text-white" style={{ backgroundColor: `#d22c5b` }} hidden>
                    <div>12:15</div>
                    <div className="font-weight-bold">Workshop</div>
                    <div className="row mt-4 mb-3">
                      <div className="col">
                        <div className="rounded-circle m-auto overflow-hidden border" style={{ width: `60px`, height: `60px`, backgroundColor: `rgb(255,255,255,0.6)`}}>
                          <img src={CosimaVonkriesImg} className="img-fluid" />
                        </div>
                        <div className="mt-3">Person</div>
                        <div><small><span style={{opacity: `0.7`}}>Job Title</span><br />Organisation</small></div>
                      </div>
                    </div>
                    <button class="btn btn-light btn-sm" type="button" data-toggle="collapse" data-target="#nintexTwo" aria-expanded="false" aria-controls="nintexTwo">
                      Read More
                    </button>
                    <div class="collapse" id="nintexTwo">
                      <div class="text-left text-white pt-3">
                        <small>
                        <p className="mb-0">more details</p>
                        </small>
                      </div>
                    </div>
                  </div>

                  <div className="shadow-sm p-3 mb-3 bg-white rounded font-weight-bold">
                    <div style={{ color: `#d22c5b` }}>12:45</div>
                    <div className="text-center" style={{ color: `#929292` }}>Networking &amp; Exhibition</div>
                  </div>

                  <div className="shadow-sm p-3 mb-3 rounded text-white" style={{ backgroundColor: `#d22c5b` }}>
                    <div>13:30</div>
                    <div className="font-weight-bold">Workshops</div>
                  </div>

                  <div className="shadow-sm p-3 mb-3 rounded text-white" style={{ backgroundColor: `#d22c5b` }} hidden>
                    <div>13:30</div>
                    <div className="font-weight-bold">NHS Digital's Hybrid Working Journey Before, During and After the Pandemic</div>
                    <div className="row mt-4 mb-3">
                      <div className="col border-right">
                        <div className="rounded-circle m-auto overflow-hidden border" style={{ width: `60px`, height: `60px`, backgroundColor: `rgb(255,255,255,0.6)`}}>
                          <img src={DanielInghamImg} className="img-fluid" />
                        </div>
                        <div className="mt-3">Daniel Ingham</div>
                        <div className="pb-3"><small><span style={{opacity: `0.7`}}>Technical Operations Manager</span><br />NHS Digital</small></div>
                      </div>
                      <div className="col border-left">
                        <div className="rounded-circle m-auto overflow-hidden border" style={{ width: `60px`, height: `60px`, backgroundColor: `rgb(255,255,255,0.6)`}}>
                          <img src={DavidTaylorImg} className="img-fluid" />
                        </div>
                        <div className="mt-3">David Taylor</div>
                        <div className="pb-3"><small><span style={{opacity: `0.7`}}>IT Channel, Consulting and Education Director</span><br />Lakeside Software</small></div>
                        <div></div>
                      </div>
                    </div>
                    <button class="btn btn-light btn-sm" type="button" data-toggle="collapse" data-target="#lakesideTwo" aria-expanded="false" aria-controls="lakesideTwo">
                      Read More
                    </button>
                    <div class="collapse" id="lakesideTwo">
                      <div class="text-left text-white pt-3">
                        <small>
                        <p>NHS Digital had already made the strategic decision to move to hybrid working before the pandemic mainly driven by a move to a new office hub following consolidation of fragmented office space around their main base in Leeds. Hybrid working started before term had even been invented and caused a rethink of the entire end user compute model. Critical to success of the transition to working from home was a solid foundation of performant devices and infrastructure delivering great user experience and NHS Digital went to market to find a digital experience monitoring (DEM) solution to help them.</p>
                        <p className="mb-0">Join this session to hear how NHS Digital innovated and brought DEM into their armoury to enable the journey from entirely office based working, to home working and now somewhere between. We will explore the good and the bad on the journey and identify the learnings we can all use to speed up our own path to successful flexible hybrid work styles in your organisation.</p>
                        </small>
                      </div>
                    </div>
                  </div>

                  <div className="shadow-sm p-3 mb-3 rounded text-white" style={{ backgroundColor: `#d22c5b` }} hidden>
                    <div>13:30</div>
                    <div className="font-weight-bold">Accelerating transformation of local public services in the UK​</div>
                    <div className="row mt-4 mb-3">
                      <div className="col border-right">
                        <div className="rounded-circle m-auto" style={{ width: `60px`, height: `60px`, backgroundColor: `rgb(255,255,255,0.6)`}}>
                          <div style={{padding: `16px`}}><FaUser size={28} /></div>
                        </div>
                        <div className="mt-3">Gerry Baker</div>
                        <div><small><span style={{opacity: `0.7`}}>Digital Transformation and Print Projects Manager</span><br />Norfolk County Council</small></div>
                      </div>
                      <div className="col border-left">
                        <div className="rounded-circle m-auto overflow-hidden border" style={{ width: `60px`, height: `60px`, backgroundColor: `rgb(255,255,255,0.6)`}}>
                          <img src={JamesSarsonImg} className="img-fluid" />
                        </div>
                        <div className="mt-3">James Sarson</div>
                        <div><small><span style={{opacity: `0.7`}}>Enterprise Account Executive, Public Sector</span><br />Adobe</small></div>
                      </div>
                    </div>
                    <button class="btn btn-light btn-sm" type="button" data-toggle="collapse" data-target="#gpaThree" aria-expanded="false" aria-controls="gpaThree">
                      Read More
                    </button>
                    <div class="collapse" id="gpaThree">
                      <div class="text-left text-white pt-3">
                        <small>
                        <p>Councils continue to face challenges with ever increasing demand and public expectations shifting to digital. Now more than ever, local authorities are looking for ways to increase productivity, maximise technology investments and deliver services more efficiently across areas like Social Care, Legal, Housing and Adult Education.</p>
                        <p className="mb-0">Join this session to find out more about the trends and challenges in Local &amp; Regional Government and how councils can continue to drive productivity and collaboration in a hybrid working environment.</p>
                        </small>
                      </div>
                    </div>
                  </div>

                  <div className="shadow-sm p-3 mb-3 bg-white rounded font-weight-bold">
                    <div style={{ color: `#d22c5b` }}>14:00</div>
                    <div className="text-center" style={{ color: `#929292` }}>Networking &amp; Exhibition</div>
                  </div>

                  <div className="shadow-sm p-3 mb-3 rounded text-white" style={{ backgroundColor: `#d22c5b` }}>
                    <div>14:45</div>
                    <div className="font-weight-bold">Selection of Live Lounges</div>
                  </div>

                  <div className="shadow-sm p-3 mb-3 rounded text-white" style={{ backgroundColor: `#d22c5b` }} hidden>
                    <div>14:45</div>
                    <div className="font-weight-bold">Tackling the challenge of productive and creative ways of working</div>
                    <div className="row mt-4 mb-3">
                      <div className="col">
                        <div className="rounded-circle m-auto overflow-hidden border" style={{ width: `60px`, height: `60px`, backgroundColor: `rgb(255,255,255,0.6)`}}>
                          <img src={SaraEdwardsImg} className="img-fluid" />
                        </div>
                        <div className="mt-3">Sara Edwards</div>
                        <div><small><span style={{opacity: `0.7`}}>Smarter Working People and Engagement Manager</span><br />Government Property Agency</small></div>
                      </div>
                    </div>
                    <button class="btn btn-light btn-sm" type="button" data-toggle="collapse" data-target="#gpaFour" aria-expanded="false" aria-controls="gpaFour">
                      Read More
                    </button>
                    <div class="collapse" id="gpaFour">
                      <div class="text-left text-white pt-3">
                        <small>
                          <p className="mb-0">Supporting creative working environments where productivity is a shared responsibility for individuals, teams and organisations. Join Sara Edwards for an introduction to the final series of our playbooks: the Productivity and Creativity Playbook. Sara will be showcasing some of the practical tools that we can all use in our everyday ways of working and as an audience member, you will be one of the first to access the playbook - you can’t miss out!</p>
                        </small>
                      </div>
                    </div>
                  </div>

                  <div className="shadow-sm p-3 mb-3 rounded text-white" style={{ backgroundColor: `#d22c5b` }} hidden>
                    <div>14:45</div>
                    <div className="font-weight-bold">Transforming for the future</div>
                    <div className="row mt-4 mb-3">
                      <div className="col border-right">
                        <div className="rounded-circle m-auto overflow-hidden border" style={{ width: `60px`, height: `60px`, backgroundColor: `rgb(255,255,255,0.6)`}}>
                          <img src={StuartWattsImg} className="img-fluid" />
                        </div>
                        <div className="mt-3">Stuart Watts</div>
                        <div><small><span style={{opacity: `0.7`}}>Smarter Working Programme Lead</span><br />Government Property Agency</small></div>
                      </div>
                      <div className="col border-left">
                        <div className="rounded-circle m-auto overflow-hidden border" style={{ width: `60px`, height: `60px`, backgroundColor: `rgb(255,255,255,0.6)`}}>
                          <img src={KateJacksonImg} className="img-fluid" />
                        </div>
                        <div className="mt-3">Kate Jackson</div>
                        <div><small><span style={{opacity: `0.7`}}>Senior Reform Partner, Modernisation and Reform Unit</span><br />Cabinet Office</small></div>
                      </div>
                    </div>
                    <button class="btn btn-light btn-sm" type="button" data-toggle="collapse" data-target="#gpaFive" aria-expanded="false" aria-controls="gpaFive">
                      Read More
                    </button>
                    <div class="collapse" id="gpaFive">
                      <div class="text-left text-white pt-3">
                        <small>
                          <p className="mb-0">The UK Civil Service has spent a number of years focusing on new and evolving working practices to ensure that it is ‘a great place to work’. In recognition of the impact of COVID and the civil service response we explore the history of smarter working, how it has helped us to mobilise ourselves and continue to deliver key public services and how we are linking it to wider civil service reform.</p>
                        </small>
                      </div>
                    </div>
                  </div>

                  <div className="shadow-sm p-3 mb-3 rounded text-white" style={{ backgroundColor: `#d22c5b` }} hidden>
                    <div>14:45</div>
                    <div className="font-weight-bold">An exploration into the new frontiers for smarter working - Re-imagining Work post Covid-19</div>
                    <div className="row mt-4 mb-3">
                      <div className="col">
                        <div className="rounded-circle m-auto overflow-hidden border" style={{ width: `60px`, height: `60px`, backgroundColor: `rgb(255,255,255,0.6)`}}>
                          <img src={ShonaAdamsImg} className="img-fluid" />
                        </div>
                        <div className="mt-3">Shona Adam</div>
                        <div><small><span style={{opacity: `0.7`}}>Workplace Change Advisor</span><br />Scottish Futures Trust</small></div>
                      </div>
                    </div>
                    <button class="btn btn-light btn-sm" type="button" data-toggle="collapse" data-target="#gpaSix" aria-expanded="false" aria-controls="gpaSix">
                      Read More
                    </button>
                    <div class="collapse" id="gpaSix">
                      <div class="text-left text-white pt-3">
                        <small>
                          <p className="mb-0">Covid-19 has created a massive change opportunity for office based working and we are all looking to understand how we apply our learnings from this situation to our future work and workplace models. Now, we are looking to re-imagine how, when and where we work and to understand the enablers that will be needed to support us. During this session we will discuss the impact Covid-19 has had to date and to explore how we can develop and support our ambitions going forward. What has worked well during this period, what behaviours do we need to embrace and what elements will be our building blocks for the future? In preparation, why not take a look at the Scottish Futures Trust workplace report which outlines the path for change post Covid-19 and are currently supporting many public sector organisations within Scotland to deliver ambitious workplace models for the future.</p>
                        </small>
                      </div>
                    </div>
                  </div>

                  <div className="shadow-sm p-3 mb-3 bg-white rounded font-weight-bold">
                    <div style={{ color: `#d22c5b` }}>15:15</div>
                    <div className="text-center" style={{ color: `#929292` }}>Networking &amp; Exhibition</div>
                  </div>

                  <div className="shadow-sm p-3 mb-3 bg-white rounded font-weight-bold">
                    <div style={{ color: `#d22c5b` }}>17:00</div>
                    <div className="text-center" style={{ color: `#929292` }}>Event Close</div>
                  </div>

                  <p className="text-center text-muted mb-0"><small>* Agenda is subject to change *</small></p>
                </AgendaDay>
              </div>
            </Row>
          </div>
          {/* <Link to="/register/" className="d-none d-md-block" style={{
            position: `fixed`,
            right: `0`,
            top: `85%`,
            backgroundColor: `#fff`,
            borderColor: `#fff`,
            color: `#d23464`,
            textTransform: `uppercase`,
            borderRadius: `0`,
            padding: `.6rem 1rem`,
            fontWeight: `600`,
            zIndex: `500`
          }}>
            Ready To<br />Register?
          </Link>
          <GridLines gridColor="#d23464">
            <div style={{
                fontFamily: `Din Pro`,
                color: `#fff`,
                background: `linear-gradient(90deg, rgba(48,196,191,1) 0%, rgba(180,1,180,1) 100%)`,
                padding: `2rem`,
                margin: `0`
            }}>
              <Timeline />
            </div>
          </GridLines> */}
        </Container>
      </div>
      <div id="registration" hidden>
      <Container>
        <PageTitle text="Registration" />

        <Row>
          <div className="col-md-6 offset-md-2">
            <p className="text-uppercase font-weight-bold text-center text-white">To attend the Smarter Working Live Virtual Conference, please complete the form below and you will be sent the details on how to join our online event.</p>
            <p className="text-uppercase font-weight-bold text-center text-white">The virtual conference is a free-to-attend event</p>
          </div>
          <div className="col-3 col-md-3">
            <PartnerCard title="Registration Partner" width="12rem" style={{ marginLeft: `1rem`, marginBottom: `4rem` }}>
              <OutboundLink href="https://www.lakesidesoftware.com/" target="_blank" rel="noopener noreferrer"><div style={{padding: `2rem 1.5rem`}}><LakesideSoftwareLogoImage /></div></OutboundLink>
            </PartnerCard>
          </div>
        </Row>

        <GridLines gridColor="#d23464">
          <div style={{
            fontFamily: `Din Pro`,
            // fontWeight: `400`,
            // fontSize: `1.5rem`,
            // textAlign: `center`,
            color: `#fff`,
            background: `linear-gradient(90deg, rgba(48,196,191,1) 0%, rgba(180,1,180,1) 100%)`,
            padding: `2rem`,
            margin: `0`
          }}>
          
          <div id="6pgb6taCkGAm6ro7q2RWdX4kW" class="hopin-container" style={{ overflow: `hidden`, paddingTop: `75.25%`, position:`relative` }}>
            <iframe style={{ border: `0`, height: `100%`, left: `0`, position: `absolute`, top: `0`, width: `100%` }} src="https://registration.hopin.com/widgets/registration/smarter-working-live-2021?widget_id=6pgb6taCkGAm6ro7q2RWdX4kW"></iframe>
          </div>
          </div>
        </GridLines>

        <br /><br />
        
        <div className="text-center">
          <PrimaryButton to="/awards/" text="Looking for the Awards Ceremony?" />
        </div>

        <br /><br /><br />

        <p className="text-center text-white mb-0"><small>* Agenda is subject to change *</small></p>
      </Container>
      </div>
      <br />
      <br />
      <br />
    </Layout>
    {/* <MyAgenda /> */}
    </MyAgendaProvider>
  </Background>
)

export default ConferencePage
